export const environment = {
  production: false,
  domain: {
    apiAuth: 'https://apidemo.lamgigio.net/core-phan-quyen/api-authentication-v2/api/auth',
    apiKhenThuongKyLuat: 'https://ktkl.api.ttithutech.com/api',
    urlWebLogIn: 'http://import-excel.ttithutech.com/#/admin/login/login',
    urlWebOld: 'http://import-excel.ttithutech.com/#',
    urlRootPortal: 'http://erp2.hutech.edu.vn/Secure/PageLogin.aspx',
    urlRootErp: 'http://erp.hutech.edu.vn/Account/Login',
    urlRootErpTest: 'http://report.hutech.edu.vn'
  },
  path: {
    dashboard: {
      capTruongChartPath: 'https://docs.google.com/spreadsheets/d/13M3LZ8e4OaV8v3PjALLpinxM9psNtYRgvqzT4GbH_JU/edit#gid=1725652438',
      capKhoaChartPath: 'https://docs.google.com/spreadsheets/d/13M3LZ8e4OaV8v3PjALLpinxM9psNtYRgvqzT4GbH_JU/edit#gid=1725652438',
    },
    quan_ly_cap_truong: {
      quan_ly_ky_luat: {
        importQuyetDinhKyLuatPath: '/admin/import-excel/request-import/list/all',
        importCapNhatKyLuatPath: '/admin/import-excel/request-import/list/all',
        importKyLuatPath: '/admin/import-excel/request-import/create/ctsv-ktkl?key=164',
      },
      quan_ly_khen_thuong: {
        importCapNhatKhenThuongPath: '/admin/import-excel/request-import/list/all',
        importKhenThuongPath: '/admin/import-excel/request-import/list/all',
      }
    },
    huong_dan: {
      huongDanPath: 'https://wiki.ttithutech.com/books/quan-ly-khen-thuong-ky-luat-cua-sinh-vien/page/gioi-thieu-chung',
    }
  },
  developer: {
    username: "NNV0050588",
    password: "123qwe@..",
  },
  storage: {
    cookies: {
      expireInSeconds: 3600 // 1 hour
    }
  },
  application: {
    http: {
      // http request timeout
      timeout: 30 * 1000 // 30 giây
    },
    metadata: {
      capcha: null,
      app: 'MOBILE_HUTECH',
      diuu: '123',
      key_option_xac_thuc: 'erp1',
    },
    ui: {
      pagingation: {
        pageSizeDefault: 10, // Số mục trên mỗi trang
        pageSizeOptions: [10, 20, 50, 100, 200], // Tùy chọn kích thước trang,
      },
    }
  }
};
